import React, { useState, useRef } from 'react';
import { CiExport, CiMenuKebab } from 'react-icons/ci';
import { GrEdit } from 'react-icons/gr';
import { MdOutlineAutoDelete } from 'react-icons/md';
import { IoIosArrowRoundUp, IoIosArrowRoundDown } from 'react-icons/io';
import { ColorRing } from 'react-loader-spinner';
import { FcSearch } from 'react-icons/fc';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { selectRoles } from '../../../Redux/AuthSlice';
import { hasPermission } from '../../../Redux/permissionSlice';
import { EyeIcon } from '@heroicons/react/16/solid';
import { BsEye } from 'react-icons/bs';

const PrescriptionTableInfo = () => {
  const [prescriptions, setPrescriptions] = useState([
    {
      id: 'RX-001',
      patient_name: 'John Doe',
      prescription_type: 'E-Prescription',
      physician: 'Dr. Emily Smith',
      medications_count: 2,
      created_at: new Date('2024-01-15').toISOString(),
      status:'pending'
    },
    {
      id: 'RX-002',
      patient_name: 'Jane Wilson',
      prescription_type: 'Written/Paper',
      physician: 'Dr. Michael Johnson',
      medications_count: 1,
      created_at: new Date('2024-02-20').toISOString(),
      status:'pending'
    }
  ]);
  const location = useLocation()
  const [searchLoading, setSearchLoading] = useState(false)
  const [search,setSearch] = useState('')
  const selectedRoles = useSelector(selectRoles);
  const roles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());
  const navigate = useNavigate()


  const [selectedDropdown, setSelectedDropdown] = useState(null);
  const popUpRef = useRef(null);

  // Sorting state
  const [showNameSort, setShowNameSort] = useState(true);
  const [showDateSort, setShowDateSort] = useState(true);

  // Toggle dropdown
  const togglePopUp = (event, id) => {
    event.stopPropagation();
    setSelectedDropdown(selectedDropdown === id ? null : id);
  };

  // Sorting handlers
  const handleSortNameAsc = () => {
    const sortedPrescriptions = [...prescriptions].sort((a, b) => 
      a.patient_name.localeCompare(b.patient_name)
    );
    setPrescriptions(sortedPrescriptions);
    setShowNameSort(false);
  };

  const handleSortNameDsc = () => {
    const sortedPrescriptions = [...prescriptions].sort((a, b) => 
      b.patient_name.localeCompare(a.patient_name)
    );
    setPrescriptions(sortedPrescriptions);
    setShowNameSort(true);
  };

  const handleSortDateAsc = () => {
    const sortedPrescriptions = [...prescriptions].sort((a, b) => 
      new Date(a.created_at) - new Date(b.created_at)
    );
    setPrescriptions(sortedPrescriptions);
    setShowDateSort(false);
  };

  const handleSortDateDsc = () => {
    const sortedPrescriptions = [...prescriptions].sort((a, b) => 
      new Date(b.created_at) - new Date(a.created_at)
    );
    setPrescriptions(sortedPrescriptions);
    setShowDateSort(true);
  };

  // Close dropdown when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        setSelectedDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const btnConditionalRendering = (specificPath) => {
    return location.pathname === specificPath;
};
const shouldRenderButton = btnConditionalRendering('/wholesale/dashboard');

  return (
   <div className='font-lufga'>
    <div className='mb-2 mx-8 '>
                <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Prescriptions ()</p>
            </div>
            <div className={prescriptions.length === 0 ? 'hidden' : 'flex flex-col gap-4 mb-4 sm:flex-row justify-between items-center font-lufga mx-6'}>
                {/* {currentPage === 1 ? <div className='flex items-center gap-2'>
                    <p className='text-sm'  >List per page</p>
                    <input className='border-[1px] w-16 px-2 text-xs' type="number" id="limit"  />
                    <button className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs" >Apply</button>
                </div> :
                    <div>
                        <p className='text-sm'  >List</p>
                    </div>} */}
                    <div className='flex items-center gap-2'>
                    <p className='text-sm'  >List per page</p>
                    <input className='border-[1px] w-16 px-2 text-xs' type="number" id="limit"  />
                    <button className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs" >Apply</button>
                </div> 
                <div className='flex flex-col sm:flex-row items-center gap-4'>
                    <div className='flex items-center gap-2'>
                        <input type='text' placeholder='Search prescriptions' value={search} onChange={(e) => { setSearch(e.target.value) }} className='w-38 sm:w-[200px] focus:outline-none rounded-md shadow-sm border px-2 py-3 h-3 font-lufga text-xs' />
                        {searchLoading ? <ColorRing
                            height="20"
                            width="20"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /> : <FcSearch 
                        // onClick={() => { handleSearchClick(search) }} 
                        className='hover:scale-110 cursor-pointer' />}
                    </div>
                    {shouldRenderButton ? null : <div>
                        {hasPermission(roles, ['inventoryWriter', 'inventoryAdmin']) &&
                            <Link to='/retail/prescriptions/add'>
                            <button 
                            className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs">Add Prescription
                            </button>
                            </Link>
                        }
                    </div>}
                    <div>
                                <button
                                    // onClick={exportFunc}
                                    className="bg-gray-200  hover:opacity-90 text-black py-1 px-4 rounded-md shadow-sm text-xs flex items-center gap-1">
                                    <CiExport />
                                    <p>Export</p>
                                </button>

                            </div>
                </div>
            </div>

     <table className='w-full text-xs px-2 table-auto overflow-auto'>
      <thead className='bg-green-200 shadow-lg sticky top-0'>
        <tr>
          <th className=''>No.</th>
          <th>ID</th>
          <th className="">
            <div className='flex justify-center items-center gap-1'>
              <p>Patient Name</p>
              {showNameSort ? 
                <IoIosArrowRoundUp 
                  onClick={handleSortNameAsc} 
                  className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" 
                /> :
                <IoIosArrowRoundDown 
                  onClick={handleSortNameDsc} 
                  className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" 
                />
              }
            </div>
          </th>
          <th className=''>Prescription Type</th>
          <th className=''>Physician</th>
          <th className=''>Medications Count</th>
          <th className=''>Status</th>
          <th className="">
            <div className='flex justify-center items-center'>
              <p>Date Created</p>
              {showDateSort ? 
                <IoIosArrowRoundUp 
                  onClick={handleSortDateAsc} 
                  className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" 
                /> :
                <IoIosArrowRoundDown 
                  onClick={handleSortDateDsc} 
                  className="text-sm cursor-pointer hover:bg-slate-50 rounded-md" 
                />
              }
            </div>
          </th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody className='py-2'>
        {prescriptions.map((prescription, index) => (
          <tr 
            key={prescription.id} 
            className={index % 2 === 0 ? 'bg-gray-100 hover:bg-slate-50' : 'bg-white hover:bg-slate-50'}
          >
            <td className='border-r-2 text-center py-4 h-20 flex-wrap'>{index + 1}</td>
            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{prescription.id}</td>
            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{prescription.patient_name}</td>
            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{prescription.prescription_type}</td>
            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{prescription.physician}</td>
            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{prescription.medications_count}</td>
            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>{prescription?.status}</td>
            <td className='border-x-2 text-center py-4 h-20 flex-wrap'>
              {new Date(prescription.created_at).toLocaleDateString()}
            </td>
            <td className='flex items-center justify-end h-20 relative'>
              <CiMenuKebab 
                className='cursor-pointer' 
                size={18} 
                onClick={(event) => togglePopUp(event, prescription.id)} 
              />
              {selectedDropdown === prescription.id && (
                <div 
                  ref={popUpRef} 
                  className='absolute flex flex-col z-10 gap-2 top-8 right-0 bg-white border rounded shadow-md min-h-max p-2 min-w-max w-20'
                >
                  <div 
                    onClick={() => {/* View Prescription */}} 
                    className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'
                  >
                    <BsEye/>
                    <p className='text-xs sm:text-sm'>View</p>
                  </div>
                  <div 
                    onClick={() => {/* Edit Prescription */}} 
                    className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'
                  >
                    <GrEdit />
                    <p className='text-xs sm:text-sm'>Edit</p>
                  </div>
                  <div 
                    onClick={() => {/* Delete Prescription */}} 
                    className='cursor-pointer justify-center hover:bg-slate-50 flex gap-2 items-center'
                  >
                    <MdOutlineAutoDelete />
                    <p className='text-xs sm:text-sm'>Delete</p>
                  </div>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
   </div>
  );
};

export default PrescriptionTableInfo;