import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import ChooseSupplier from './ChooseSupplier';
import SelectProducts from './SelectProducts';
import OrderSummary from './OrderSummary';
import { IoArrowBack, IoArrowBackCircleOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../../utils/api';
import { api_url } from '../../../config';
import { ColorRing } from 'react-loader-spinner';
import { useOrders } from '../../../context/orderContext';

const AddOrder = () => {
    const [orderData, setOrderData] = useState({
        supplier: null,
        products: [],
        comment: ''
    });
    const [loader, setLoader] = useState(false)
    const [error, setError] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();
    const requiredRoles = location.state?.requiredRoles || [];
    const { setLoading } = useOrders()
    const steps = [
        { name: 'Choose Supplier', path: '' },
        { name: 'Select Products', path: 'select-products' },
        { name: 'Overview', path: 'summary' }
    ];

    const getStepClass = (stepPath) => {
        const currentPath = location.pathname.split('/').pop();
        if (currentPath === stepPath) {
            return 'text-blue-500';
        }
        if (steps.findIndex(step => step.path === stepPath) < steps.findIndex(step => step.path === currentPath)) {
            return 'text-green-500';
        }
        return 'text-gray-400';
    };

    const updateOrderData = (data) => {
        setOrderData(prevData => ({ ...prevData, ...data }));
    };

    const { id, branch_id, token } = useSelector((state) => state.auth)


    useEffect(() => {
        const currentPath = location.pathname.split('/').pop();
        if (currentPath === 'select-products' && !orderData.supplier) {
            navigate('/retail/orders/addOrders/', { state: { requiredRoles } });
        } else if (currentPath === 'summary' && (!orderData.supplier || orderData.products.length === 0)) {
            navigate('/retail/orders/addOrders/', { state: { requiredRoles } });
        }
    }, [location, orderData, navigate]);


    const addOrder = async (e) => {
        e.preventDefault();

        if (!orderData.supplier || orderData.products.length === 0) {
            setError('Please fill all required fields');
            setTimeout(() => setError(''), 6000);
            return;
        }

        const { supplier, products ,comment} = orderData;

        const OrderDetails = {
            data: {
                w_branch_id: supplier.branch_id,
                inventory: products.map(product => ({
                    id: product.id,
                    demand_quantity: product.quantity
                }))
            }
        };

        try {
            setLoader(true);
            const response = await api.post(
                `${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/order`,
                OrderDetails,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            const orderId = response.data.data.id

            if(comment){
                await api.post(`${api_url}/api/v1/retailer/user/${id}/branch/${branch_id}/order/${orderId}/comments`,{ content:comment },{
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`
                    }
                })
            }
            // Handle successful response
            toast.success('Order added successfully');
            setLoader(false);

            // Reset form or navigate away
            setLoading(true)
            navigate('/retail/orders', { state: { requiredRoles } });
        } catch (error) {
            setLoader(false);
            setError(error?.response?.data?.error || 'Failed adding Order');
            setTimeout(() => setError(''), 6000);
            toast.error('Failed adding Order');
        }
    };




    return (
        <div className='font-lufga'>


            <div className='mb-2 mx-8 flex items-center gap-6'>
                <p className='lg:text-2xl text-lg font-semibold text-slate-600'>Add Order</p>
                <button onClick={() => {
                    setLoading(true)
                    navigate('/retail/orders', { state: { requiredRoles } });
                }} 
                className=' text-xs font-semibold text-slate-600 hover:underline cursor-pointer flex items-center gap-[1px] '>
                    <IoArrowBack />
                    <p>Go to Orders</p>
                </button>
            </div>
            <div className="flex items-center space-x-4 mb-4">
                {steps.map((step, index) => (
                    <div key={index} className="flex items-center text-xs sm:text-base">
                        <div className={`flex items-center  ${getStepClass(step.path)}`}>
                            <div className={`rounded-full w-4 h-4 flex items-center justify-center mr-2 ${getStepClass(step.path)}`}>
                                <div className="w-2 h-2 bg-current rounded-full"></div>
                            </div>
                            <span>{step?.name}</span>
                        </div>
                        {index < steps.length - 1 && <span className="mx-2 text-gray-400">→</span>}
                    </div>
                ))}
            </div>
            <Routes>
                <Route
                    path="/"
                    element={<ChooseSupplier
                        onSelectSupplier={(supplier) => {
                            updateOrderData({ supplier });
                            navigate('select-products', { state: { requiredRoles } });
                        }}
                    />}
                />
                <Route
                    path="select-products"
                    element={<SelectProducts
                        supplier={orderData.supplier}
                        onProductsSelected={(products) => {
                            updateOrderData({ products });
                            navigate('summary', { state: { requiredRoles } });
                        }}
                    />}
                />
                <Route
                    path="summary"
                    element={<OrderSummary
                        orderData={orderData}
                        onCommentChange={(comment) => updateOrderData({ comment })}
                        onSubmitOrder={addOrder}
                        loading={loader}

                    />}
                />
            </Routes>
        </div>
    );
}

export default AddOrder;