import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import Layout from "./components/Wholesalelib/Layout";
import Dashboard from "./pages/Wholesale/Dashboard";
import Summary from "./pages/Wholesale/Summary";
import Suppliers from "./pages/Wholesale/Suppliers";
import Retailer from "./pages/Wholesale/Retailer";
import Orders from "./pages/Wholesale/Orders";
import Inventory from "./pages/Wholesale/Inventory";
import Stocks from "./pages/Wholesale/Stocks";
import Users from "./pages/Wholesale/Users";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import RetailLayout from "./pages/Retail/RetailLayout";
import RetailDashboard from "./pages/Retail/RetailDashboard";
import RetailSummary from "./pages/Retail/RetailSummary";
import { Outlet } from 'react-router-dom';
import Sales from "./pages/Wholesale/Sales";
import Home from "./pages/Home";
// import RetailInventory from "./pages/Retail/RetailInventory";
// import RetailSupplier from "./pages/Retail/RetailSupplier";
// import RetailOrderPage from "./pages/Retail/RetailOrderPage";
// import RetailStock from "./pages/Retail/RetailStock";
import PrivateRoute from "./utils/PrivateRoute";
import ForgotPasswordPage from "./pages/Wholesale/forgotPasswordPage";
import ResetPasswordPage from "./pages/Wholesale/resetPasswordPage";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { InfinitySpin } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { selectRoles } from "./Redux/AuthSlice";
import RetailSuppliers from "./pages/Retail/Suppliers";
import RetailInventory from "./pages/Retail/Inventory";
import RetailStocks from "./pages/Retail/Stocks";
import RetailSales from "./pages/Retail/Sales";
import RetailUsers from "./pages/Retail/Users";
import RetailOrderPage from "./pages/Retail/RetailOrderPage";
import RetailForgotPasswordPage from "./pages/Retail/ForgotPasswordPage"
import UserProfilePage from "./pages/Wholesale/UserProfilePage";
import RetailUserProfilePage from "./pages/Retail/UserProfilePage";
import AddOrder from "./components/Retaillib/orders/AddOrder";
import OrderData from "./components/Wholesalelib/orders/OrderData";
import RetailOrderData from "./components/Retaillib/orders/OrderData"
import { OrderProvider } from "./context/orderContext";
import PrescriptionPage from "./pages/Retail/PrescriptionPage";
import Customer from "./pages/Wholesale/Customer";
import AddPrescription from "./components/Retaillib/prescriptions/AddPrescription";
import PrescriptionTableInfo from "./components/Retaillib/prescriptions/PrescriptionTableInfo";



function App() {
  useEffect(() => {
    const handleOffline = () => {
      toast.error("You're offline");
    };

    const handleOnline = () => {
      toast.success("You're back online");
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  function OrderRoutes() {
    return (
      <OrderProvider>
        <Outlet />
      </OrderProvider>
    );
  }

  return (
    <BrowserRouter >

      <Routes>
        <Route >
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          {/* <Route path="/forgot-wholesale-pass" element={<ForgotPasswordPage />} />
          <Route path="/forgot-retail-pass" element={<RetailForgotPasswordPage />} /> */}

        </Route>

        <Route element={<PrivateRoute type="pharmtrix-wholesale" />}>
          <Route path="/wholesale/" element={<Layout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="summary" element={<Summary />} />
            <Route path="suppliers" element={<Suppliers />} />
            <Route path="retailers" element={<Retailer />} />
            <Route path="customers" element={<Customer />} />
            <Route path="orders" element={<OrderRoutes />}>
              <Route index element={<Orders />} />
              <Route path=":id" element={<OrderData />} />
            </Route>
            <Route path="inventory" element={<Inventory />} />
            <Route path="stocks" element={<Stocks />} />
            <Route path="sales" element={<Sales />} />
            <Route path="users" element={<Users />} />
            <Route path="profile" element={<UserProfilePage />} />
          </Route>
        </Route>

        <Route element={<PrivateRoute type="pharmtrix-retail" />} >
          <Route path="/retail/" element={<RetailLayout />}>
            <Route path="dashboard" element={<RetailDashboard />} />
            <Route path="summary" element={<RetailSummary />} />
            <Route path="suppliers" element={<RetailSuppliers />} />
            <Route path="stocks" element={<RetailStocks />} />
            <Route path="sales" element={<RetailSales />} />
            <Route path="prescriptions" element={<PrescriptionPage />}>
              <Route index element={<PrescriptionTableInfo />} />
              <Route path="add" element={<AddPrescription />} />
            </Route>
            <Route path="inventory" element={<RetailInventory />} />
            <Route path="users" element={<RetailUsers />} />
            <Route path="orders" element={<OrderRoutes />}>
              <Route index element={<RetailOrderPage />} />
              <Route path="addOrders/*" element={<AddOrder />} />
              <Route path=":id" element={<RetailOrderData />} />
            </Route>
            <Route path="profile" element={<RetailUserProfilePage />} />

          </Route>
        </Route>
      </Routes>



    </BrowserRouter>
  );
}

export default App;


