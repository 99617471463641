import React from 'react';
import PrescriptionTableInfo from '../../components/Retaillib/prescriptions/PrescriptionTableInfo';
import { Outlet } from 'react-router';

const PrescriptionPage = () => {
    return (
      
        <div>
            <Outlet/>
        </div>
    );
};

export default PrescriptionPage;