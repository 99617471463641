import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import api from '../../../utils/api';
import { api_url } from '../../../config';
import { toast } from 'react-toastify';
import { ColorRing } from 'react-loader-spinner';

const ManageCategoriesModal = ({
    isOpen,
    onClose,
    token,
    branchId,
    userId,
    categories,
    fetchCategories
}) => {
    const [newCategory, setNewCategory] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const [editedName, setEditedName] = useState('');
    const [loading, setLoading] = useState(false);
    const [catLoading, setCatLoading] = useState(false);


    const handleAddCategory = async () => {
        if (!newCategory.trim()) return;
        try {
            setLoading(true)
            await api.post(
                `${api_url}/api/v1/wholesaler/user/${userId}/branch/${branchId}/contact/category`,
                { name: newCategory },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast.success('Category added successfully');
            setNewCategory('');
            fetchCategories();
        } catch (error) {
            toast.error('Failed to add category');
        } finally {
            setLoading(false)
        }
    };

    const startEditing = (category) => {
        setEditingCategory(category.id);
        setEditedName(category.name);
    };

    const cancelEditing = () => {
        setEditingCategory(null);
        setEditedName('');
    };

    const handleEditCategory = async (categoryId) => {
        if (!editedName.trim()) return;
        try {
            setCatLoading(true)
            await api.put(
                `${api_url}/api/v1/wholesaler/user/${userId}/branch/${branchId}/contact/category/${categoryId}`,
                { name: editedName },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast.success('Category updated successfully');
            setEditingCategory(null);
            setEditedName('');
            fetchCategories();
        } catch (error) {
            toast.error('Failed to update category');
        } finally {
            setCatLoading(false)
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            await api.delete(
                `${api_url}/api/v1/wholesaler/user/${userId}/branch/${branchId}/categories/${categoryId}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            toast.success('Category deleted successfully');
            fetchCategories();
        } catch (error) {
            toast.error('Failed to delete category');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-lg w-96 mx-2 max-h-[90vh] overflow-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">Manage Categories</h2>
                    <IoMdClose
                        onClick={onClose}
                        className="cursor-pointer hover:text-red-500"
                        size={24}
                    />
                </div>

                <div className="flex mb-4">
                    <input
                        type="text"
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        placeholder="New category name"
                        className="flex-grow border p-2 rounded-l text-sm"
                    />
                    <button
                        onClick={handleAddCategory}
                        className="bg-blue-500 text-white text-sm px-4 rounded-r hover:bg-blue-600"
                    >
                        {loading ? <ColorRing
                            visible={true}
                            height="20"
                            width="20"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                        /> : 'Add'}
                    </button>
                </div>

                <div className="space-y-2">
                    {categories?.map((category) => (
                        <div key={category.id} className="flex items-center justify-between border-b py-2">
                            {editingCategory === category.id ? (
                                <input
                                    type="text"
                                    value={editedName}
                                    onChange={(e) => setEditedName(e.target.value)}
                                    className="flex-grow border p-1 rounded mr-2 text-sm"
                                    autoFocus
                                />
                            ) : (
                                <span className="text-sm">{category.name}</span>
                            )}
                            <div className="space-x-2">
                                {editingCategory === category.id ? (
                                    <>
                                        <button
                                            onClick={() => handleEditCategory(category.id)}
                                            className="text-green-500 hover:text-green-600 text-sm"
                                        >
                                            {catLoading ? <ColorRing
                                                visible={true}
                                                height="20"
                                                width="20"
                                                ariaLabel="color-ring-loading"
                                                wrapperStyle={{}}
                                                wrapperClass="color-ring-wrapper"
                                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                            /> : 'Save'}
                                        </button>
                                        <button
                                            onClick={cancelEditing}
                                            className="text-gray-500 hover:text-gray-600 text-sm"
                                        >
                                            Cancel
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        onClick={() => startEditing(category)}
                                        className="text-blue-500 hover:text-blue-600 hover:underline text-sm"
                                    >
                                        Edit
                                    </button>
                                )}
                                {/* <button 
                                    onClick={() => handleDeleteCategory(category.id)}
                                    className="text-red-500 hover:text-red-600 text-sm"
                                >
                                    Delete
                                </button> */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ManageCategoriesModal;