import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HiChevronRight, HiOutlineAdjustments, HiOutlineChartBar, HiOutlineCube, HiOutlineDocumentText, HiOutlineFolderAdd, HiOutlineReceiptRefund, HiOutlineUsers, HiOutlineViewGrid } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../Redux/permissionSlice';
import { selectRoles } from '../../Redux/AuthSlice';
import { IoCartOutline } from 'react-icons/io5';
import { MdOutlineInventory } from 'react-icons/md';
import { BsPrescription2 } from "react-icons/bs";
import { FaPrescriptionBottleAlt, FaUserInjured } from 'react-icons/fa';


const RetailSidebar = () => {
    // const [activeMenuItem, setActiveMenuItem] = useState('');
    // const userRoles = useSelector((state)=>state.auth.roles);
    const [isPrescriptionOpen, setIsPrescriptionOpen] = useState(false);
    const location = useLocation()
    const navigate = useNavigate()
    const selectedRoles = useSelector(selectRoles);
    const userRoles = Array.isArray(selectedRoles) ? selectedRoles : selectedRoles.split(',').map(role => role.trim());


    // const handleMenuItemClick = (menuItem, requiredRoles) => {
    //     setActiveMenuItem(menuItem);
    //     // sessionStorage.setItem('requiredRoles', JSON.stringify(requiredRoles));
    // };

    const getActiveMenuItem = (path) => {
        if (path.includes('/retail/dashboard')) return 'Dashboard';
        if (path.includes('/retail/summary')) return 'Summary';
        if (path.includes('/retail/suppliers')) return 'Suppliers';
        if (path.includes('/retail/retailers')) return 'Retailers';
        if (path.includes('/retail/orders')) return 'Orders';
        if (path.includes('/retail/inventory')) return 'Inventories';
        if (path.includes('/retail/stocks')) return 'Stocks';
        if (path.includes('/retail/sales')) return 'Sales';
        if (path.includes('/retail/prescriptions')) return 'Prescriptions';
        if (path.includes('/retail/prescriptions/patients')) return 'Patients';
        if (path.includes('/retail/prescriptions/medications')) return 'Medications';
        if (path.includes('/retail/users')) return 'Users';
        return '';
    };

    const togglePrescriptionSubmenu = () => {
        // navigate('/retail/prescriptions')
        setIsPrescriptionOpen(!isPrescriptionOpen);
    };
    const activeMenuItem = getActiveMenuItem(location.pathname);


    return (
        <div className=' h-full overflow-y-auto'>
            <div className='flex-col mb-8'>
                <p className='text-neutral-200 p-2 cursor-pointer bg-green-700 shadow-lg font-semibold text-center tracking-widest'>RETAIL</p>
            </div>

            <div className='flex flex-col gap-1 '>
                {hasPermission(userRoles, ['executiveViewer']) && (
                    <Link to='/retail/dashboard'
                        state={{ requiredRoles: ['executiveViewer'] }}

                    >
                        <div
                            className={`flex items-center rounded-md gap-2 p-2 hover:opacity-75 ${activeMenuItem === 'Dashboard' ? 'bg-green-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Dashboard')}
                        >
                            <HiOutlineViewGrid size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Dashboard' ? 'text-[#BFF6C3]' : ''}`}>Dashboard</button>
                        </div>
                    </Link>
                )}

                <Link to='/retail/summary'>
                    <div
                        className={`flex items-center rounded-md gap-2 p-2 hover:opacity-75 ${activeMenuItem === 'Summary' ? 'bg-green-900' : ''}`}
                    // onClick={() => handleMenuItemClick('Summary')}
                    >
                        <HiOutlineCube size={20} className='text-white' />
                        <button className={`text-white ${activeMenuItem === 'Summary' ? 'text-[#BFF6C3]' : ''}`}>Summary</button>
                    </div>
                </Link>

                {hasPermission(userRoles, ['supplierViewer', 'supplierWriter', 'supplierAdmin']) && (
                    <Link to='/retail/suppliers'
                        state={{ requiredRoles: ['supplierViewer', 'supplierWriter', 'supplierAdmin'] }}
                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Suppliers' ? 'bg-green-900 ' : ''}`}
                        // onClick={() => handleMenuItemClick('Suppliers')}
                        >
                            <HiOutlineAdjustments size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Suppliers' ? 'text-[#BFF6C3]' : ''}`}>Suppliers</button>
                        </div>
                    </Link>
                )}




                {hasPermission(userRoles, ['orderViewer', 'orderWriter', 'orderAdmin']) && (
                    <Link to='/retail/orders'
                        state={{ requiredRoles: ['orderViewer', 'orderWriter', 'orderAdmin'] }}
                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Orders' ? 'bg-green-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Orders')}
                        >
                            <IoCartOutline size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Orders' ? 'text-[#CAF4FF]' : ''}`}>Orders</button>
                        </div>
                    </Link>
                )}

                {hasPermission(userRoles, ['inventoryViewer', 'inventoryWriter', 'inventoryAdmin']) && (
                    <Link to='/retail/inventory'
                        state={{ requiredRoles: ['inventoryViewer', 'inventoryWriter', 'inventoryAdmin'] }}

                    >
                        <div
                            className={`flex items-center  gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Inventories' ? 'bg-green-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Inventories')}
                        >
                            <MdOutlineInventory size={20} className='text-white' />

                            <button className={`text-white ${activeMenuItem === 'Inventories' ? 'text-[#BFF6C3]' : ''}`}>Inventories</button>
                        </div>
                    </Link>
                )}

                {hasPermission(userRoles, ['stockViewer', 'stockWriter', 'stockAdmin']) && (
                    <Link to='/retail/stocks'
                        state={{ requiredRoles: ['stockViewer', 'stockWriter', 'stockAdmin'] }}
                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Stocks' ? 'bg-green-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Stocks')}
                        >
                            <HiOutlineChartBar size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Stocks' ? 'text-[#BFF6C3]' : ''}`}>Stocks</button>
                        </div>
                    </Link>
                )}

                {hasPermission(userRoles, ['transactionViewer', 'transactionWriter', 'transactionAdmin']) && (
                    <div>
                      <Link to='/retail/prescriptions'
                         state={{ requiredRoles: ['transactionViewer', 'transactionWriter', 'transactionAdmin'] }}

                      >
                      <div
                            onClick={togglePrescriptionSubmenu}
                            className={`flex items-center justify-between gap-2 p-2 rounded-md hover:opacity-75 cursor-pointer ${activeMenuItem === 'Prescriptions' ? 'bg-green-900' : ''}`}
                        >
                            <div className='flex items-center gap-2'>
                                <BsPrescription2 size={20} className='text-white' />
                                <span className={`text-white ${activeMenuItem === 'Prescriptions' ? 'text-[#BFF6C3]' : ''}`}>
                                    Prescriptions
                                </span>
                            </div>
                            <HiChevronRight
                                size={20}
                                className={`text-white transition-transform duration-300 ${isPrescriptionOpen ? 'rotate-90' : ''}`}
                            />
                        </div>
                      </Link>

                        {/* Prescription Submenu */}
                        <div
                            className={`transition-all duration-300 ease-in-out overflow-hidden ${isPrescriptionOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
                                }`}
                        >
                            <div className='pl-6 mt-1'>
                                <Link to='/retail/prescriptions/patients'
                                    state={{ requiredRoles: ['transactionViewer', 'transactionWriter', 'transactionAdmin'] }}
                                >
                                    <div
                                        className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Patients' ? 'bg-green-900' : ''}`}
                                    >
                                        <FaUserInjured size={16} className='text-white' />
                                        <button className={`text-white text-sm ${activeMenuItem === 'Patients' ? 'text-[#BFF6C3]' : ''}`}>
                                            Patients
                                        </button>
                                    </div>
                                </Link>
                                {/* <Link to='/retail/prescriptions/medications'
                                    state={{ requiredRoles: ['transactionViewer', 'transactionWriter', 'transactionAdmin'] }}
                                >
                                    <div
                                        className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Medications' ? 'bg-green-900' : ''}`}
                                    >
                                        <FaPrescriptionBottleAlt size={16} className='text-white' />
                                        <button className={`text-white text-sm ${activeMenuItem === 'Medications' ? 'text-[#BFF6C3]' : ''}`}>
                                            Medications
                                        </button>
                                    </div>
                                </Link> */}
                            </div>
                        </div>
                    </div>
                )}
                {hasPermission(userRoles, ['transactionViewer', 'transactionWriter', 'transactionAdmin']) && (
                    <Link to='/retail/sales'
                        state={{ requiredRoles: ['transactionViewer', 'transactionWriter', 'transactionAdmin'] }}

                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Sales' ? 'bg-green-900' : ''}`}
                        // onClick={() => handleMenuItemClick('Sales')}
                        >
                            <HiOutlineDocumentText size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Sales' ? 'text-[#BFF6C3]' : ''}`}>Sales</button>
                        </div>
                    </Link>
                )}

                {hasPermission(userRoles, ['userViewer', 'userWriter']) && (
                    <Link to='/retail/users'
                        state={{ requiredRoles: ['userViewer', 'userWriter', 'userAdmin'] }}

                    >
                        <div
                            className={`flex items-center gap-2 p-2 rounded-md hover:opacity-75 ${activeMenuItem === 'Users' ? 'bg-green-900 ' : ''}`}
                        // onClick={() => handleMenuItemClick('Users')}
                        >
                            <HiOutlineUsers size={20} className='text-white' />
                            <button className={`text-white ${activeMenuItem === 'Users' ? 'text-[#BFF6C3]' : ''}`}>Users</button>
                        </div>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default RetailSidebar;
