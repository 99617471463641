import React, { useState } from 'react';
import { useNavigate } from 'react-router';

const AddPrescription = () => {
    const navigate = useNavigate()
    const [prescriptionType, setPrescriptionType] = useState('');
    const [patientInfo, setPatientInfo] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        address: '',
        phone: '',
        idType: '',
        idNumber: ''
    });

    const [physicianInfo, setPhysicianInfo] = useState({
        firstName: '',
        lastName: '',
        npi: '',
        phone: '',
        hospitalClinic: ''
    });

    const [medications, setMedications] = useState([
        {
            name: '',
            strength: '',
            instructions: '',
            quantity: 0,
            allergies: '',
            refills: 0,
            date: ''
        }
    ]);

    const handlePatientInfoChange = (field, value) => {
        setPatientInfo({ ...patientInfo, [field]: value });
    };

    const handlePhysicianInfoChange = (field, value) => {
        setPhysicianInfo({ ...physicianInfo, [field]: value });
    };

    const handleMedicationChange = (index, field, value) => {
        const updatedMedications = [...medications];
        updatedMedications[index][field] = value;
        setMedications(updatedMedications);
    };

    const addMedication = () => {
        setMedications([
            ...medications,
            {
                name: '',
                strength: '',
                instructions: '',
                quantity: 0,
                allergies: '',
                refills: 0,
                date: ''
            }
        ]);
    };

    const removeMedication = (index) => {
        if (medications.length > 1) {
            const updatedMedications = [...medications];
            updatedMedications.splice(index, 1);
            setMedications(updatedMedications);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission
        console.log('Patient Info:', patientInfo);
        console.log('Physician Info:', physicianInfo);
        console.log('Medications:', medications);
    };

    const isPatientInfoFilled = Object.values(patientInfo).every(value => value !== '');
    const isPhysicianInfoFilled = Object.values(physicianInfo).every(value => value !== '');

    return (
        <div className="font-lufga w-full">
            <div className='mb-2 mx-8 '>
                <p className='lg:text-3xl text-xl font-semibold text-slate-600'>Add Prescription</p>
                <button
                    onClick={() => { navigate(-1) }}
                    className='text-sm font-semibold text-slate-600 hover:underline'>Go back</button>
            </div>
            <div>
                <form onSubmit={handleSubmit} className='p-2 sm:p-4 max-w-[1440px] mx-auto'>
                    <div className="mb-4 w-fit">
                        <label className=" font-medium mb-2">
                            Prescription Type
                        </label>
                        <select
                            className="w-full p-2 border rounded"
                            value={prescriptionType}
                            onChange={(e) => setPrescriptionType(e.target.value)}
                            required
                        >
                            <option value="">Select Prescription Type</option>
                            <option value="Verbal/Phone">Verbal/Phone</option>
                            <option value="Written/Paper">Written/Paper</option>
                            <option value="E-Prescription">E-Prescription</option>
                        </select>
                    </div>
                    <div className={`p-4 border-b shadow rounded-md ${!prescriptionType ? 'opacity-50 pointer-events-none' : ''}`}>
                        <h2 className="text-lg font-medium mb-2">Patient Info</h2>
                        <div className="grid sm:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="firstName" className="block font-medium text-sm">First Name</label>
                                <input type="text" id="firstName" value={patientInfo.firstName} onChange={(e) => handlePatientInfoChange('firstName', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="lastName" className="block font-medium text-sm">Last Name</label>
                                <input type="text" id="lastName" value={patientInfo.lastName} onChange={(e) => handlePatientInfoChange('lastName', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="dob" className="block font-medium text-sm">Date of Birth</label>
                                <input type="date" id="dob" value={patientInfo.dob} onChange={(e) => handlePatientInfoChange('dob', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="gender" className="block font-medium text-sm">Gender</label>
                                <select
                                    className="w-full p-2 border rounded"
                                    value={patientInfo.gender}
                                    onChange={(e) => handlePatientInfoChange('gender', e.target.value)}
                                    required
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="address" className="block font-medium text-sm">Address</label>
                                <input type="text" id="address" value={patientInfo.address} onChange={(e) => handlePatientInfoChange('address', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="phone" className="block font-medium text-sm">Phone</label>
                                <input type="tel" id="phone" value={patientInfo.phone} onChange={(e) => handlePatientInfoChange('phone', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="idType" className="block font-medium text-sm">ID Type</label>
                                <input type="text" id="idType" value={patientInfo.idType} onChange={(e) => handlePatientInfoChange('idType', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="idNumber" className="block font-medium text-sm">ID Number</label>
                                <input type="text" id="idNumber" value={patientInfo.idNumber} onChange={(e) => handlePatientInfoChange('idNumber', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                        </div>
                    </div>

                    <div className={`p-4 mt-4 border-b shadow rounded-md ${!isPatientInfoFilled ? 'opacity-50 pointer-events-none' : ''}`}>
                        <h2 className="text-lg font-medium mb-2">Physician Info</h2>
                        <div className="grid sm:grid-cols-2 gap-4">
                            <div>
                                <label htmlFor="physicianFirstName" className="block font-medium text-sm">First Name</label>
                                <input type="text" id="physicianFirstName" value={physicianInfo.firstName} onChange={(e) => handlePhysicianInfoChange('firstName', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="physicianLastName" className="block font-medium text-sm">Last Name</label>
                                <input type="text" id="physicianLastName" value={physicianInfo.lastName} onChange={(e) => handlePhysicianInfoChange('lastName', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="npi" className="block font-medium text-sm">NPI</label>
                                <input type="text" id="npi" value={physicianInfo.npi} onChange={(e) => handlePhysicianInfoChange('npi', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="physicianPhone" className="block font-medium text-sm">Phone</label>
                                <input type="tel" id="physicianPhone" value={physicianInfo.phone} onChange={(e) => handlePhysicianInfoChange('phone', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                            <div>
                                <label htmlFor="hospitalClinic" className="block font-medium text-sm">Hospital/Clinic</label>
                                <input type="text" id="hospitalClinic" value={physicianInfo.hospitalClinic} onChange={(e) => handlePhysicianInfoChange('hospitalClinic', e.target.value)} className="border px-2 py-1 rounded w-full" />
                            </div>
                        </div>
                    </div>

                    <div className={`p-4 border-b shadow rounded-md mt-4 ${!isPhysicianInfoFilled ? 'opacity-50 pointer-events-none' : ''}`}>
                        <h2 className="text-lg font-medium mb-2">Medication Info</h2>
                        {medications.map((medication, index) => (
                            <div key={index} className="border rounded p-4 mb-4 relative">
                                {medications.length > 1 && (
                                    <button
                                        type="button"
                                        onClick={() => removeMedication(index)}
                                        className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded text-xs hover:bg-red-600"
                                    >
                                        Remove
                                    </button>
                                )}
                                <div className="grid sm:grid-cols-2 gap-4">
                                    <div>
                                        <label htmlFor={`medication-name-${index}`} className="block font-medium text-sm">Medication Name</label>
                                        <input
                                            type="text" id={`medication-name-${index}`} value={medication.name} onChange={(e) => handleMedicationChange(index, 'name', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor={`medication-strength-${index}`} className="block font-medium text-sm">Strength (Dosage)</label>
                                        <input type="text" id={`medication-strength-${index}`} value={medication.strength} onChange={(e) => handleMedicationChange(index, 'strength', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                   
                                    <div>
                                        <label htmlFor={`medication-quantity-${index}`} className="block font-medium text-sm">Quantity</label>
                                        <input type="number" id={`medication-quantity-${index}`} value={medication.quantity} onChange={(e) => handleMedicationChange(index, 'quantity', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor={`medication-refills-${index}`} className="block font-medium text-sm">No. of Refills</label>
                                        <input type="number" id={`medication-refills-${index}`} value={medication.refills} onChange={(e) => handleMedicationChange(index, 'refills', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                    <div>
                                        <label htmlFor={`medication-instructions-${index}`} className="block font-medium text-sm">Instructions</label>
                                        <textarea id={`medication-instructions-${index}`} value={medication.instructions} onChange={(e) => handleMedicationChange(index, 'instructions', e.target.value)} className="border px-2 py-1 rounded w-full h-20"></textarea>
                                    </div>
                                    <div>
                                        <label htmlFor={`medication-allergies-${index}`} className="block font-medium text-sm">Allergies</label>
                                        <textarea type="text" id={`medication-allergies-${index}`} value={medication.allergies} onChange={(e) => handleMedicationChange(index, 'allergies', e.target.value)} className="border px-2 py-1 rounded w-full h-20" />
                                    </div>

                                    
                                    <div>
                                        <label htmlFor={`medication-date-${index}`} className="block font-medium text-sm">Date</label>
                                        <input type="date" id={`medication-date-${index}`} value={medication.date} onChange={(e) => handleMedicationChange(index, 'date', e.target.value)} className="border px-2 py-1 rounded w-full" />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="flex space-x-2">
                            <button
                                type="button"
                                onClick={addMedication}
                                className="bg-gray-500 hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-xs"
                            >
                                Add Medication
                            </button>
                        </div>
                    </div>

                    <div className="flex justify-end mt-4">
                        <button type="submit" className="bg-green-500  hover:opacity-90 text-white py-1 px-4 rounded-md shadow-sm text-sm">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddPrescription;